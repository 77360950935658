import store from './store.js';
import { watchEffect } from 'vue';
import { parse } from './parse.js';

const watchPython = () => {
  try {
    let schema = parse(store.state.authoredPython)
    let result = JSON.stringify(schema, null, 2)
    store.state.jsonContent = result;
    store.state.schemaSchema = schema;
  } catch(e) {
    console.log(e)
  }
}

watchEffect(watchPython)