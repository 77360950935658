<script>
import Brue from 'brue';
import store from './../store.js'
import RenderedModel from './RenderedModel'

const SchemaOutput = Brue(() => {

  const getDependenciesOfType = (type, modelsByName) => {
    if(type.arguments && type.modifier != 'union'){
      let dependencies = []
      type.arguments.forEach(arg => {
        dependencies = dependencies.concat(getDependenciesOfType(arg, modelsByName))
      });
      return dependencies
    }
    else if(modelsByName[type]) return [type]
    else return []
  }

  const findDependencies = (model, modelsByName) => {
    let dependencies = []
    model.fields.forEach(field => {
      dependencies = dependencies.concat(getDependenciesOfType(field.type, modelsByName))
    })
    return dependencies
  }

  return $ => { 
    let models = store.state.schemaSchema
    let modelsByName = {}
    let roots = {}
    models.forEach(model => {
      modelsByName[model.name] = model
      roots[model.name] = true
    })
    models.forEach(model => {
      let dependencies = findDependencies(model, modelsByName)
      console.log(dependencies)
      dependencies.forEach(dep => {
        delete roots[dep]
      })
    })

    $
    .a('div .schema-output')
    Object.keys(roots).forEach(rootName => { $
      .b('pre')
        .c(RenderedModel, {model: modelsByName[rootName], tabLevel: 0, modelsByName})
    })
  }

})
export default SchemaOutput;
</script>

<style lang="postcss">
.schema-output {
  
}
</style>