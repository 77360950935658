<script>
import Brue from 'brue';
import AceEditor from './AceEditor.vue'
import { debounce } from './../utility.js'
import store from './../store.js'
import constants from './../constants.js'

const PythonPane = Brue(() => {

  const saveContent = debounce(str => {
    store.state.authoredPython = str;
  }, 400)

  store.state.authoredPython = constants.defaultPython

  return $ => { $
    .a('div .python-pane')
      .b('div .python-header .header')
        .c('div .python-title .title').text('Source')
        .c('div .python-subtitle .subtitle').text('python classes')
      .b('div .python-content')
        .c(AceEditor, {language: 'python', value: constants.defaultPython})
          .on('update', e => {
            saveContent(e)
          })
  }

})
export default PythonPane;
</script>

<style lang="postcss">
.python-pane {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.python-content {
  background: #444;
  flex: 1;
}
</style>