<template>
  <EditorView />
</template>

<script>
import EditorView from './components/EditorView.vue'
import './translate.js'

window.console.warn = () => {}

export default {
  name: 'App',
  components: {
    EditorView
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
  height: 100%;
}
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.title {
  font-family: Roboto;
  font-weight: 900;
  text-transform: uppercase;
  color: #abd8ff;
  padding: 10px;
  font-size: 16px;
}
.subtitle {
  font-family: Roboto;
  padding: 10px;
  padding-left: 0px;
  color: #CCC;
}
.ace-one-dark .ace_comment {
  color: #888 !important;
}

*::-webkit-scrollbar {
  background-color: #272b33;
}
*::-webkit-scrollbar-thumb {
  background-color: #323947;
}
</style>
