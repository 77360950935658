<script>
import Brue from 'brue';

const spaces = (tabLevel, adj = 0) => ' '.repeat(Math.max(tabLevel * 3 + adj,0))

const RenderedModel = Brue('model', 'tabLevel', 'modelsByName', props => {
  return $ => { 
    let tabLevel = props.tabLevel + 1;
    $
    .a('span .render-model')
      .b('span .render-header').text(props.model.name)
      .b('span .render-curly-bracket').html(' {<br />');
    props.model.fields.forEach(field => { $
      .b().text(spaces(tabLevel))
      .b('span .render-key').text(field.name)
      .b().text(': ')
      .b(Type, {type: field.type, tabLevel, modelsByName: props.modelsByName})
      if(field.comment){ $
      .b('br')
      .b().text(spaces(tabLevel))
      .b('span .render-comment').text(' # '+field.comment)
      } $
      .b('br')
    }); $
      .b('span .render-curly-bracket').text(spaces(tabLevel-1)  + '}')

  }
})

const Type = Brue('type','tabLevel','inArray', 'modelsByName', props => {
  return $ => {

    let modelsByName = props.modelsByName;
    let modifier = props.type.modifier;
    let tabLevel = props.tabLevel;

    if(modifier == 'array'){ $
      .a('span .render-array')
        .b('span .render-bracket').text('[ ');
      props.type.arguments.forEach(arg => { $
        .b(Type, {type: arg, tabLevel, inArray: true, modelsByName})
      }); $
        .b('span .render-bracket').text(' ]')
    } else if(modifier == 'optional'){ $
      .a('span')
        .b('span .render-optional').text('optional ')
        .b(Type, {type: props.type.arguments[0], tabLevel, modelsByName})
    } else if(modifier == 'union'){ $
      .a('span')
      props.type.arguments.forEach((arg, i) => {
        if(arg in modelsByName) { $
          .b('span .render-header').text(arg)
        } else { $
          .b(Type, {type: arg, tabLevel, modelsByName})
        }
        if(i < props.type.arguments.length -1) { $
          .b('span .rendered-or').text(' or ')
        }
      })
    } else if(modifier == 'object'){
      let containsModel;
      let valueType = props.type.arguments[1]
      if(modelsByName && valueType in modelsByName) {
          containsModel = true;
      } 
      $
      .a('span .render-object')
        .b('span .render-curly-bracket').text('{')
      if(containsModel){ $
        .b('br')
        .b().text(spaces(tabLevel+1))
      } else { $
        .b().text(' ')
      } $
        .b('span .render-type').text(props.type.arguments[0])
        .b().text(': ')
        .b(Type, {type: valueType, tabLevel: tabLevel + 1, modelsByName})
      if(containsModel){ $
        .b('br')
        .b().text(spaces(tabLevel))
      } else {$
        .b().text(' ')
      } $
        .b('span .render-curly-bracket').text('}')
    } else if (modelsByName && props.type in modelsByName) { $
      .a('span')
      if(props.inArray){ $
        .b('br')
        .b().text(spaces(tabLevel+1))
      } else { $
        .b().text(' ')
      } $
        .b(RenderedModel, {model: modelsByName[props.type], tabLevel: props.inArray ? tabLevel+1 : tabLevel, modelsByName})
      if(props.inArray){ $
        .b('br')
        .b().text(spaces(tabLevel).slice(0,-1))
      }
    } else { 
      $
      .a('span .render-type').text(props.type)
    }

  }
})

export default RenderedModel;

</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.render-optional {
  color: #cf6b71;
}
.render-header {
  color: #98b755;
  font-weight: 700;
}
.render-key {
  color: #CCC;
}
.render-object-key {
  color: #999;
}
.render-carrot {
  color: #777;
}
.render-bracket {
  font-size: 16px;
  color: #CCC;
}
.render-curly-bracket {
  font-size: 15px;
  color: #AAA;
}
.render-type {
  color: #4fafef;
}
pre {
  font-family: 'Roboto Mono';
  font-size: 14px;
  line-height: 1.45;
  background-color: #272b33;
  color: #999;
  padding: 10px 20px 10px 20px;
}
</style>