<template>
  <div class="editor-wrap">
     <v-ace-editor class="editor" :readonly="readOnly" v-model:value="content" @init="editorInit" :lang="language" theme="one-dark" style="height: 100%; width: 100%;" />
  </div>
</template>

<script>
import ace from 'ace-builds/src-min-noconflict/ace';
ace
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-one-themes';
import { VAceEditor } from 'vue3-ace-editor';

export default {
  name: 'HelloWorld',
  props: {
    language: String,
    value: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VAceEditor
  },

  data() {
    return {
      content: this.value,
      editorInit(editor){
        editor
      }
    }
  },
  watch: {
    value: function(newVal){
      this.content = newVal
    }
  },
  editorInit(){
    console.log('init')
  },
  updated(){
    this.$emit('update', this.content)
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

.editor {
  font-family: 'Fira Code';
  font-size: 14px;
  line-height: 1.45! important;
}
.editor-wrap {
  width: 100%;
  height: 100%;
  padding-top: 8px;
  background: #262a31;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.editor {
  width: 600px;
  height: 800px;
}
.ace_scroller {
  color: #333;
}
</style>
