<script>
import Brue from 'brue';
import SchemaOutput from './SchemaOutput.vue';

const OutputPane = Brue(() => {

  return $ => { $
    .a('div .output-pane')
      .b('div .output-header .header')
        .c('div .output-title .title').text('Output')
      .b('div .output-content-window')
        .c('div .output-content')
          .d(SchemaOutput)
  }

})
export default OutputPane;
</script>

<style lang="postcss">
.output-pane {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.output-content-window {
  overflow: hidden;
  position: relative;
  flex: 1;
}
.output-content {
  background: #272b33;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: left;
  overflow: auto;
}
</style>