const constants = {
  defaultPython:
`class Type:
    modifier: str # "array" "object" "optional" "union"
    arguments: List[Union[str, Type]]

class Field:
    name: str
    type: Union[str, Type]
    description: Optional[List[str]]
    comment: Optional[str]
    tags: Optional[List[str]]
    info: Optional[Dict[str,str]]

class Model:
    name: str
    fields: List[Field]
    description: Optional[List[str]]
    comment: Optional[str]
    tags: Optional[List[str]]
    info: Optional[Dict[str,str]]

class SchemaSchema:
    hello: str

'''
SchemaSchema
[pretty-neat, niche usage]
status -> beta
created -> 5/31/21
Schemaschema is a tool for parsing schemas from typed python
classes. At the moment it's output is most useful for pasting 
into Google docs. It's not _actually_ running python, so don't be 
surprised when it can't comprehend things.

Other outputs like MkDocs, Avro, JSON Schema etc. to come, as well
as a command line interface to play nice with automated workflows.

Why python? I thought it was an easy and concise way to express models.
'''`
}

export default constants;