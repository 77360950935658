import { reactive } from 'vue';

const store = {

  state: reactive({
    authoredPython: '',
    authoredJson: '',
    pythonContent: '',
    jsonContent: '',
    schemaSchema: []
  })

}
export default store;