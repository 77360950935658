<script>
import Brue from 'brue';
import AceEditor from './AceEditor.vue'
import store from './../store.js'

const JsonPane = Brue(() => {

  return $ => { 
    $
    .a('div .json-pane')
      .b('div .json-header .header')
        .c('div .json-title .title').text('JSON')
        .c('div .json-subtitle .subtitle').text('schema schema')
      .b('div .json-content')
        .c(AceEditor, {language: 'json', value: store.state.jsonContent, readOnly: true })
  }

})
export default JsonPane;
</script>

<style lang="postcss">
.json-pane {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.json-content {
  background: #444;
  flex: 1;
}
</style>