<script>
import Brue from 'brue';
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import PythonPane from './PythonPane.vue'
import JsonPane from './JsonPane.vue'
import OutputPane from './OutputPane.vue'

const EditorView = Brue(() => {

  return $ => { $
    .a('div .editor-view')
      .b('div .header')
        .c('div .logo')
          .d('div .first-schema .title-schema').text('Schema')
          .d('div .second-schema .title-schema').text('Schema')
          .d('div .beta').text('beta')
      .b('div .body')
        .c(Splitpanes, {vertical: true})
          .d(Pane)
            .e(PythonPane)
          .d(Pane)
            .e(JsonPane)
          .d(Pane)
            .e(OutputPane)
    
  }

})
export default EditorView;
</script>
<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@1,700&display=swap');

.editor-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #808080;
}
.logo {
  text-align: left;
  padding-left: 16px;
  line-height: 1.5;
  font-family: 'Zilla Slab';
  font-weight: 700;
  font-style: italic;
  text-transform: lowercase;
  font-size: 32px;
}
.first-schema {
  color: #FFF;
}
.second-schema {
  color: #CCC;
  margin-left: 2px;
}
.beta {
  display: inline-block;
  font-size: 22px;
  margin-left: 4px;
  color: #222;
}
.title-schema {
  display: inline-block;
}

.body {
  flex: 1;
}
.editor-view .splitpanes__splitter {
width: 5px;
background: #CCC;
}

</style>